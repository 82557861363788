/* =============================================================================
 * Text align
============================================================================= */
.t-text-align-center {
	text-align:center;
}

.t-text-align-right {
	text-align:right;
}
