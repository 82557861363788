/* =============================================================================
 * Footer
 * [1] Don't really like this, but opens the possibility for editors to edit
	   the footer.
============================================================================= */
.c-footer {
	color:var(--color-white);
	background-color:var(--color-grass);
	padding-top:var(--spacing-m);
	padding-bottom:var(--spacing-m);
	font-size:0.882em;
	line-height:1.6;
}

.s-page-footer h2,
.s-page-footer .c-footer__heading {
	line-height:2;
	color:rgba(255, 255, 255, 0.6);
	font-weight:bold;
	font-size:0.8em;
	text-transform:uppercase;
}

.c-footer .c-icon--naringsliv,
.c-footer .c-icon--naringsliv > svg {
	width:175px;
	height:36px;
}

.s-page-footer a {
	color:var(--color-white);
	border-bottom:1px solid #ffffff4d;
}

/* [1] */
.s-page-footer .c-footer__image-link {
	border-bottom:0;
}

@media(--md) {
	.c-footer__column:last-child {
		text-align:right;
	}
}