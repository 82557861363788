/* =============================================================================
 * Search form
============================================================================= */
.c-search-form {
	@mixin text-style-reset;
	position:relative;
	z-index:15;
	display:-ms-flexbox;
	display:flex;
}

.c-search-form__field {
	@mixin box-shadow 0.05;
	position:relative;
	-ms-flex-positive:1;
	flex-grow:1;
}

.c-search-form__input {
	@mixin text-style-reset;
	background-color:var(--color-white);
	border:1px solid var(--color-white);
	color:var(--color-main-label);
	padding-left:54px;
	height:100%;
	min-height:48px;
	transition:var(--transition);
}

.c-form .c-search-form__field {
	background-color:var(--color-white);
	@mixin box-shadow 0.05;
}


.c-form .c-search-form__input {
	box-shadow:none;
	@media(--md) {
		height:60px;
	}
}

.c-search-form:hover,
.c-search-form:focus {
	border-color:var(--color-turtle-green);
}

.c-search-form__icon {
	color:var(--color-grass);
	position:absolute;
	left:18px;
	top:50%;
	margin-top:-12px;
}

.c-search-form__button {
	margin-left:24px;
}

.c-search-form__dropdown {
	@mixin box-shadow 0.05;
	position:absolute;
	width:100%;
	padding:20px;
	color:var(--color-main-label);
	border-top:1px solid rgba(0, 0, 0, 0.1);
	background-color:var(--color-white);
	line-height:30px;
}

.c-search-form__dropdown-heading {
	font-style:italic;
}

.c-search-form__suggestion {
	cursor:default;
}

.c-search-form__suggestion.is-active {
	background:#ff0;
}

.c-search-form__suggestions-highlight {
	font-weight:bold;
	background:none;
}
