.c-files {
	max-width: 400px;
}

.c-files__area {
	position: relative;
	overflow: hidden;
	border-radius: 4px;
	border: 1px solid var(--color-battleship-grey);
	background-color: #fff;
	padding: 24px;
}

.c-files__label {
	display: block;
	cursor: pointer;
}

.c-files__label-inner,
.c-files__label-end-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: opacity 0.3s, transform 0.3s ease-in-out;
}

.c-files__label-inner .c-files__icon,
.c-files__label-end-inner .c-files__icon {
	font-size: 42px;
}

.c-files__label-title {}

/* End Label - message visible when files have been dropped */
.c-files__label-end {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}

.c-files__label-end-inner {
	opacity: 0;
	transform: translateY(25px);
}

.c-files__icon {
	display: inline-block;
	color: inherit;
	fill: currentColor;
	height: 1em;
	width: 1em;
	line-height: 1;
	flex-shrink: 0;
	max-width: initial;
	font-size: 24px;
}

/* files have been selected */
.c-files__area--file-selected .c-files__label-inner {
	opacity: 0;
	transform: translateY(-25px);
}

.c-files__area--file-selected .c-files__label-end-inner {
	opacity: 1;
	transform: translateY(0px);
}

/* file icons in the back */
.c-files__area--file-selected .c-files__icon *:nth-child(1),
.c-files__area--file-selected .c-files__icon *:nth-child(2) {
	opacity: 0.7;
	transform: rotate(0deg);
}

.js .c-files__label-end {
	display: flex;
}
