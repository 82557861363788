.signin {
	width: 100%;
	max-width: 560px;
	background-color: #fff;
	padding: 40px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 1px 2px 0px rgb(0, 0, 0, 0.12);
}

.signin__methods {
	margin-bottom: 32px;
}


.signin__method-header {
	margin-bottom: 24px;
}

.signin__method-title {
	font-size: 24px;
	margin: 0 0 16px;
	text-align: center;
}

.signin__method-subTitle {
	font-size: 16px;
	font-weight: 700;
	margin: 0;
	text-align: center;
}

.sign__mainButtonOuter {
	display: flex;
	justify-content: center;
}

.signin__loginMethods {
	text-align: center;
}

.signin__loginMethods ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.signin__loginMethods li {
	padding: 4px 0;
	font-size: 14px;
}

.signin__createAccount {
	border-top: 1px solid var(--color-light-grey);
	padding-top: 32px;
	margin-top: 32px;
	font-size: 14px;
	text-align: center;
}

.signin__createAccount .c-button {
	margin:8px auto;
}

.signin__page {
	width: 100%;
	height: 100%;
	padding: 0;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	
	/* Make sure that the ontrust-consent banner (cookie bar) doesn't lay on-top of this page when closed (bug issue DP-1524) */
	position: relative;
    z-index: 1000;
    background: var(--color-main-background);
}

@media (--md) {
	.signin__method {
		margin-bottom: 16px;
	}

	.signin__page {
		padding: 48px 0;
	}

}
