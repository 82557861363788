/* =============================================================================
 * Forms
============================================================================= */

/*
 * 1. Enable line-wrapping in IE8+
 * 2. Enable line-wrapping in other browsers
 */
legend {
	display:table; /* [1] */
	white-space:normal; /* [2] */
}

label {
	display:inline-block;
}

label em {
	color:#767676;
}

select {
	width:100%;
}

input:not([type]),
[type="text"],
[type="url"],
[type="email"],
[type="tel"],
[type="number"],
[type="password"],
[type="search"],
textarea {
	width:100%;
	padding:10px;
	outline:none;
	border:1px solid #000;
	border-radius:0;
	-webkit-appearance:none;
}

input:not([type])[disabled],
[type="text"][disabled],
[type="url"][disabled],
[type="email"][disabled],
[type="tel"][disabled],
[type="number"][disabled],
[type="password"][disabled],
[type="search"][disabled],
[type="button"][disabled],
textarea [disabled],
button[disabled] {
	cursor:not-allowed;
}

/*
 * 1. Reset button look in webkit
 */
[type="reset"],
[type="button"],
[type="submit"],
button {
	-webkit-appearance:none; /* [1] */
}

/*
 * 1. Reset search input look in webkit, making it possible to
 *    style it as a normal text input
 */
[type="search"] {
	-webkit-appearance:none; /* [1] */
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display:none; /* [1] */
}

/*
 * 1. Placeholder styling for Gecko 18- (first two rows of selector)
 *    and Gecko 19+ (second two rows of selector)
 */
input:-moz-placeholder,
textarea:-moz-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder {
	/* [1] */
	color:rgb(128, 128, 128);
	opacity:1;
}

/*
 * 1. Placeholder styling for webkit
 */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color:rgb(128, 128, 128); /* [1] */
}

/*
 * 1. Placeholder styling for IE10
 */
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color:rgb(128, 128, 128); /* [1] */
}

/*
 * 1. Reset extra padding on gecko buttons
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	/* [1] */
	padding:0;
	border:0;
}

fieldset {
	border:none;
}

legend {
	margin-bottom:10px;
	font-weight:400;
	line-height:20px;
	text-transform:uppercase;
}
