/* Form Item
-------------------------------------------------------------- */
.c-form__item {
	margin-bottom:12px;
}

.c-form__item--no-margin {
	margin-bottom:0;
}

.c-form__error[class],
.c-form__error[class]:focus,
.c-form__error[class]:hover,
.c-form__error[class] + .c-checkbox__label::after {
	border-color:var(--color-dusty-orange);
}

.c-form__error[class]:not(.c-rect-input__input--small),
.c-form__error[class]:not(.c-rect-input__input--small):focus,
.c-form__error[class]:not(.c-rect-input__input--small):hover,
.c-form__error[class] + .c-checkbox__label::after {
	margin-bottom:12px;
}

.c-form__error-message,
.c-form__error-message-group {
	color:var(--color-dusty-orange);
	font-style:italic;
}

.c-form__error-message--small {
	background-color:var(--color-white);
	border:1px solid var(--color-dusty-orange);
	bottom:-56px;
	left:0;
	padding:12px 6px;
	position:absolute;
	white-space:nowrap;
	z-index:2;
}


/* EPI forms
-------------------------------------------------------------- */
.EPiServerForms {
	background:rgb(248, 248, 248);
	padding:2.118em;
}

.EPiServerForms h2 {
	font-weight:700;
	font-size:21px;
	line-height:1.33;
	margin-bottom:0.294em;
}

.Form__Status .EPiServerForms .Form__Status .Form__Warning__Message {
	background:rgb(198, 228, 185);
	color:#000;
	font:500 15px/1.6 "Lab-grotesque", Helvetica, sans-serif;
}

.FormCaptcha__Image,
.EPiServerForms .FormCaptcha .FormCaptcha__Refresh {
	display:block;
	margin:5px 0;
}

.EPiServerForms .Form__Element {
	margin:0 0 0.75rem 0;
}

/* Input */
[type=email],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=url],
input:not([type]),
textarea {
	border:1px solid rgb(255, 255, 255);
	box-shadow:0 0 12px 0 rgba(0, 0, 0, 0.05);
	color:#1a1a1a;
	font:400 15px/1.6 "Lab-grotesque", Helvetica, sans-serif;
	line-height:1.6;
	padding:12px;
}

/* Buttons */
.EPiServerForms button {
	font:400 15px/1.6 "Lab-grotesque", Helvetica, sans-serif;
	border-radius:48px;
	color:rgb(31, 31, 31);
	display:-ms-inline-flexbox;
	display:inline-flex;
	-ms-flex-pack:center;
	justify-content:center;
	margin:5px 0;
	padding:12px 24px;
	transition:all 0.16s ease;
	width:auto;
}

.EPiServerForms button[disabled] {
	background:rgb(230, 230, 230);
	box-shadow:0 0 0 6px rgb(241, 241, 241);
	color:rgb(31, 31, 31);
}

.EPiServerForms .FormSubmitButton {
	background-color:rgb(75, 169, 37);
	color:rgb(255, 255, 255);
	cursor:pointer;
	border:none;
	box-shadow:0 0 0 6px rgb(211, 236, 200);
	font-weight:bold;
}
