.c-intro {
	padding-bottom:var(--spacing-s);
}

.c-intro::after {
	display:table;
	clear:both;
	content:"";
}

.c-intro__heading {
	@mixin text-style-alpha;
	word-wrap:break-word;
	overflow-wrap:break-word;
}

.c-intro__topic {
	display:block;
	margin-top:1em;
	font-size:0.706em;
	color:var(--color-grass);
	font-weight:bold;
	letter-spacing:1.5px;
	text-transform:uppercase;
}

.c-intro__preamble {
	@mixin leading-dash var(--color-turtle-green);
	margin-top:var(--spacing-xs);
}

.c-intro__preamble--white-dash {
	@mixin leading-dash var(--color-white);
}

/* Make right content pull right */
.c-intro__right-content {
	margin:var(--spacing-sm) 0 0 auto;
}

.c-intro__entry {
	text-align:right;
}

.c-intro__position {
	@mixin trailing-dash var(--color-turtle-green), 72px;
	@mixin text-style-lambda;
	font-style:italic;
	margin-top:var(--spacing);
}

.c-intro__action {
	margin-top:var(--spacing-sm);
}

.c-intro__action\@md {
	display:none;
}

.c-intro__action\@md .c-intro__cta-alt {
	display:block;
	padding-right:0;
	padding-top:36px;
	text-align:right;
}

.c-intro__cta {
	display:inline-block;
	white-space:nowrap;
}

.c-intro__cta-alt {
	padding-right:36px;
	display:inline-block;
}

.c-intro__thumbnail {
	margin-top:0;
	margin-bottom:var(--spacing-m);
}

@media(--md) {
	.c-intro {
		padding-bottom:var(--spacing-m);
	}

	.c-intro__right-content {
		margin-top:0;
	}

	.c-intro__action {
		display:none;
	}

	.c-intro__action\@md {
		display:block;
		margin-top:0;
		max-height:39px;
		overflow:visible;
	}

	.c-intro__thumbnail {
		margin-top:-var(--spacing-sm);
		margin-bottom:0;
	}

	.c-intro__preamble {
		margin-top:var(--spacing-s);
	}

	.c-intro__preamble::before {
		margin-bottom:var(--spacing-sm);
	}
}
