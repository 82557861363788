@define-mixin leading-dash $color {
	&::before {
		display:block;
		width:72px;
		height:1px;
		margin-bottom:var(--spacing-s);
		background-color:$color;
		content:"";
		@media(--md) {
			margin-bottom:36px;
		}
	}
}

@define-mixin trailing-dash $color, $width {
	&::after {
		display:block;
		width:$width;
		height:1px;
		margin-top:var(--spacing-sm);
		background-color:$color;
		content:"";
	}
}

@define-mixin gradient-box {
	background-image:linear-gradient(to bottom, var(--color-white-grey), var(--color-white));
	box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

/* Reversed gradient for boxes on white backgrounds */
@define-mixin gradient-box-inverted {
	background-image:linear-gradient(to top, rgb(253, 253, 253), rgb(255, 255, 255));
}

@define-mixin reset-gradient-box {
	background-image:none;
	box-shadow:none;
}

@define-mixin width-gutter {
	padding-left:var(--gutter);
	padding-right:var(--gutter);
	@media(--sm) {
		padding-left:var(--gutter-sm);
		padding-right:var(--gutter-sm);
	}
	@media(--md) {
		padding-left:var(--gutter-md);
		padding-right:var(--gutter-md);
	}
	@media(--lg) {
		padding-left:var(--gutter-lg);
		padding-right:var(--gutter-lg);
	}
}

@define-mixin left-negative-margin {
	margin-left:-21px;
	padding-left:21px;
	@media(--md) {
		margin-left:-58px;
		padding-left:58px;
	}
	@media(--xl) {
		margin-left:-88px;
		padding-left:88px;
	}
}

@define-mixin right-negative-margin {
	margin-right:-21px;
	padding-right:21px;
	@media(--md) {
		margin-right:-56px;
		padding-right:56px;
	}
	@media(--lg) {
		margin-right:-60px;
		padding-right:60px;
	}
	@media(--xl) {
		margin-right:-80px;
		padding-right:80px;
	}
}

@define-mixin right {
	right:21px;
	@media(--md) {
		right:56px;
	}
	@media(--lg) {
		right:60px;
	}
	@media(--xl) {
		right:80px;
	}
}

@define-mixin pointer-up $dimension, $color, $offset {
	&::before {
		content:"";
		display:block;
		width:0;
		height:0;
		position:absolute;
		top:-$dimension;
		left:$offset;
		border-style:solid;
		border-width:0 $dimension $dimension $dimension;
		border-color:transparent transparent $color transparent;
	}
}

@define-mixin box-shadow $transparency {
	box-shadow:0 0 12px 0 rgba(0, 0, 0, $transparency);
}

@define-mixin md-margin-right-m {
	@media(--md) {
		margin-right:var(--spacing-m);
	}
}

@define-mixin main-content-margin {
	margin-bottom:var(--spacing-ml);
	@media(--md) {
		margin-bottom:var(--spacing-xxl);
	}
}
