/* =============================================================================
 * Grid
 * [1] 	Hack in order for IE to behave with images inside grid, 
 		read more https://github.com/philipwalton/flexbugs/issues/75
============================================================================= */
:root {
	--o-grid-gutter:20px;
	@media(--md) {
		--o-grid-gutter:40px;
	}
}

.o-grid {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	width:calc(100% + calc(var(--o-grid-gutter) / 2));
	margin-right:calc(var(--o-grid-gutter) * -0.25);
	margin-left:calc(var(--o-grid-gutter) * -0.25);
	min-height:0.1px; /* [1] */

	@media(--md) {
		width:calc(100% + var(--o-grid-gutter));
		margin-right:calc(var(--o-grid-gutter) * -0.5);
		margin-left:calc(var(--o-grid-gutter) * -0.5);
	}
}

.o-grid.row-reverse {
	-ms-flex-direction:row-reverse;
	flex-direction:row-reverse;
}

.o-grid > .o-grid__column {
	display:inline-block;
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	flex-direction:column;
	padding-right:calc(var(--o-grid-gutter) * 0.25);
	padding-left:calc(var(--o-grid-gutter) * 0.25);
	vertical-align:top;
	@media(--md) {
		padding-right:calc(var(--o-grid-gutter) * 0.5);
		padding-left:calc(var(--o-grid-gutter) * 0.5);
	}
}

.o-grid--small {
	width:calc(100% + var(--o-grid-gutter / 2));
	margin:0 calc(var(--o-grid-gutter / 2) * -0.5);
}

.o-grid--small > .o-grid__column {
	padding-right:calc(var(--o-grid-gutter / 2) * 0.5);
	padding-left:calc(var(--o-grid-gutter / 2) * 0.5);
}

.o-grid.o-grid--align-bottom {
	-ms-flex-align:end;
	align-items:flex-end;
}

.o-grid.o-grid--align-center {
	-ms-flex-align:center;
	align-items:center;
}

.o-grid.o-grid--justify-center {
	-ms-flex-pack:center;
	justify-content:center;
}

.o-grid.o-grid--space-between {
	-ms-flex-pack:justify;
	justify-content:space-between;
}

.o-grid.o-grid--align-right {
	-ms-flex-pack:end;
	justify-content:flex-end;
}

.o-grid--no-flex > .o-grid__column {
	display: block;
}

.o-grid__column.o-grid__column--block {
	display:block;
}

.o-grid__column.o-grid__column--align-center {
	-ms-flex-item-align:center;
	-ms-grid-row-align:center;
	align-self:center;
}

.o-grid__column.o-grid__column--align-bottom {
	-ms-flex-item-align:end;
	align-self:flex-end;
}

.o-grid--collapsed {
	margin-right:0;
	margin-left:0;
}

.o-grid--collapsed > .o-grid__column {
	padding-right:0;
	padding-left:0;
}

.o-grid--horizontal-collapse > .o-grid__column {
	margin-bottom:-1px;
}

.o-grid--bottom-spacing {
	margin-top:-var(--o-grid-gutter);
}

.o-grid--bottom-spacing > .o-grid__column {
	padding-top:var(--o-grid-gutter);
}

.o-grid--small.o-grid--bottom-spacing {
	margin-top:-calc(var(--o-grid-gutter) / 2);
}

.o-grid--small.o-grid--bottom-spacing > .o-grid__column {
	padding-top:calc(var(--o-grid-gutter) / 2);
}

@media(--lg) {
	/* Switch order on the first two child elements */

	.o-grid.o-grid--switched > .o-grid__column:nth-child(1) {
		-ms-flex-order:2;
		order:2;
	}

	.o-grid.o-grid--switched > .o-grid__column:nth-child(2) {
		-ms-flex-order:1;
		order:1;
	}
}

/* Below medium
----------------------------------------------------------------------------- */
@media(--below-md) {
	.o-grid > [data-size~="below-md:1"] {
		width:calc(100% * 1/12);
	}

	.o-grid > [data-size~="below-md:2"] {
		width:calc(100% * 2/12);
	}

	.o-grid > [data-size~="below-md:3"] {
		width:calc(100% * 3/12);
	}

	.o-grid > [data-size~="below-md:4"] {
		width:calc(100% * 4/12);
	}

	.o-grid > [data-size~="below-md:5"] {
		width:calc(100% * 5/12);
	}

	.o-grid > [data-size~="below-md:6"] {
		width:calc(100% * 6/12);
	}

	.o-grid > [data-size~="below-md:7"] {
		width:calc(100% * 7/12);
	}

	.o-grid > [data-size~="below-md:8"] {
		width:calc(100% * 8/12);
	}

	.o-grid > [data-size~="below-md:9"] {
		width:calc(100% * 9/12);
	}

	.o-grid > [data-size~="below-mdmd:10"] {
		width:calc(100% * 10/12);
	}

	.o-grid > [data-size~="below-md:11"] {
		width:calc(100% * 11/12);
	}

	.o-grid > [data-size~="below-md:12"] {
		width:calc(100% * 12/12);
	}
}

/* Small
----------------------------------------------------------------------------- */
.o-grid > [data-size~="sm:0"] {
	display:none;
}

.o-grid > [data-size="1"],
.o-grid > [data-size~="sm:1"] {
	width:calc(100% * 1/12);
}

.o-grid > [data-size="2"],
.o-grid > [data-size~="sm:2"] {
	width:calc(100% * 2/12);
}

.o-grid > [data-size="3"],
.o-grid > [data-size~="sm:3"] {
	width:calc(100% * 3/12);
}

.o-grid > [data-size="4"],
.o-grid > [data-size~="sm:4"] {
	width:calc(100% * 4/12);
}

.o-grid > [data-size="5"],
.o-grid > [data-size~="sm:5"] {
	width:calc(100% * 5/12);
}

.o-grid > [data-size="6"],
.o-grid > [data-size~="sm:6"] {
	width:calc(100% * 6/12);
}

.o-grid > [data-size="7"],
.o-grid > [data-size~="sm:7"] {
	width:calc(100% * 7/12);
}

.o-grid > [data-size="8"],
.o-grid > [data-size~="sm:8"] {
	width:calc(100% * 8/12);
}

.o-grid > [data-size="9"],
.o-grid > [data-size~="sm:9"] {
	width:calc(100% * 9/12);
}

.o-grid > [data-size="10"],
.o-grid > [data-size~="sm:10"] {
	width:calc(100% * 10/12);
}

.o-grid > [data-size="11"],
.o-grid > [data-size~="sm:11"] {
	width:calc(100% * 11/12);
}

.o-grid > [data-size="12"],
.o-grid > [data-size~="sm:12"] {
	width:calc(100% * 12/12);
}

/* Medium
----------------------------------------------------------------------------- */
@media(--md) {
	.o-grid > [data-size~="sm:0"] {
		display:block;
	}

	.o-grid > [data-size~="md:1"] {
		width:calc(100% * 1/12);
	}

	.o-grid > [data-size~="md:2"] {
		width:calc(100% * 2/12);
	}

	.o-grid > [data-size~="md:3"] {
		width:calc(100% * 3/12);
	}

	.o-grid > [data-size~="md:4"] {
		width:calc(100% * 4/12);
	}

	.o-grid > [data-size~="md:5"] {
		width:calc(100% * 5/12);
	}

	.o-grid > [data-size~="md:6"] {
		width:calc(100% * 6/12);
	}

	.o-grid > [data-size~="md:7"] {
		width:calc(100% * 7/12);
	}

	.o-grid > [data-size~="md:8"] {
		width:calc(100% * 8/12);
	}

	.o-grid > [data-size~="md:9"] {
		width:calc(100% * 9/12);
	}

	.o-grid > [data-size~="md:10"] {
		width:calc(100% * 10/12);
	}

	.o-grid > [data-size~="md:11"] {
		width:calc(100% * 11/12);
	}

	.o-grid > [data-size~="md:12"] {
		width:calc(100% * 12/12);
	}
}

/* Large
----------------------------------------------------------------------------- */
@media(--lg) {
	.o-grid > [data-size~="lg:1"] {
		width:calc(100% * 1/12);
	}

	.o-grid > [data-size~="lg:2"] {
		width:calc(100% * 2/12);
	}

	.o-grid > [data-size~="lg:3"] {
		width:calc(100% * 3/12);
	}

	.o-grid > [data-size~="lg:4"] {
		width:calc(100% * 4/12);
	}

	.o-grid > [data-size~="lg:5"] {
		width:calc(100% * 5/12);
	}

	.o-grid > [data-size~="lg:6"] {
		width:calc(100% * 6/12);
	}

	.o-grid > [data-size~="lg:7"] {
		width:calc(100% * 7/12);
	}

	.o-grid > [data-size~="lg:8"] {
		width:calc(100% * 8/12);
	}

	.o-grid > [data-size~="lg:9"] {
		width:calc(100% * 9/12);
	}

	.o-grid > [data-size~="lg:10"] {
		width:calc(100% * 10/12);
	}

	.o-grid > [data-size~="lg:11"] {
		width:calc(100% * 11/12);
	}

	.o-grid > [data-size~="lg:12"] {
		width:calc(100% * 12/12);
	}
}

/* Extra large
----------------------------------------------------------------------------- */
@media(--xl) {
	.o-grid > [data-size~="xl:1"] {
		width:calc(100% * 1/12);
	}

	.o-grid > [data-size~="xl:2"] {
		width:calc(100% * 2/12);
	}

	.o-grid > [data-size~="xl:3"] {
		width:calc(100% * 3/12);
	}

	.o-grid > [data-size~="xl:4"] {
		width:calc(100% * 4/12);
	}

	.o-grid > [data-size~="xl:5"] {
		width:calc(100% * 5/12);
	}

	.o-grid > [data-size~="xl:6"] {
		width:calc(100% * 6/12);
	}

	.o-grid > [data-size~="xl:7"] {
		width:calc(100% * 7/12);
	}

	.o-grid > [data-size~="xl:8"] {
		width:calc(100% * 8/12);
	}

	.o-grid > [data-size~="xl:9"] {
		width:calc(100% * 9/12);
	}

	.o-grid > [data-size~="xl:10"] {
		width:calc(100% * 10/12);
	}

	.o-grid > [data-size~="xl:11"] {
		width:calc(100% * 11/12);
	}

	.o-grid > [data-size~="xl:12"] {
		width:calc(100% * 12/12);
	}
}

/* Smallest
----------------------------------------------------------------------------- */
@media(--s) {
	.o-grid > [data-size~="s:1"] {
		width:calc(100% * 1/12);
	}

	.o-grid > [data-size~="s:2"] {
		width:calc(100% * 2/12);
	}

	.o-grid > [data-size~="s:3"] {
		width:calc(100% * 3/12);
	}

	.o-grid > [data-size~="s:4"] {
		width:calc(100% * 4/12);
	}

	.o-grid > [data-size~="s:5"] {
		width:calc(100% * 5/12);
	}

	.o-grid > [data-size~="s:6"] {
		width:calc(100% * 6/12);
	}

	.o-grid > [data-size~="s:7"] {
		width:calc(100% * 7/12);
	}

	.o-grid > [data-size~="s:8"] {
		width:calc(100% * 8/12);
	}

	.o-grid > [data-size~="s:9"] {
		width:calc(100% * 9/12);
	}

	.o-grid > [data-size~="s:10"] {
		width:calc(100% * 10/12);
	}

	.o-grid > [data-size~="s:11"] {
		width:calc(100% * 11/12);
	}

	.o-grid > [data-size~="s:12"] {
		width:calc(100% * 12/12);
	}
}


/* Order - Smallest
----------------------------------------------------------------------------- */
@media(--s) {
	@for $i from 1 to 11 {
		.o-grid > [data-order="$i"],
		.o-grid > [data-order~="s:$i"] {
			-ms-flex-order:$(i);
			order:$(i);
		}
	}
}
