.t-show-for-small[class] {
	display:inherit;
}

.t-show-for-medium-up[class] {
	display:none;
}

.t-show-for-medium-up-block[class] {
	display:none;
}

@media(--md) {
	.t-show-for-small[class] {
		display:none;
	}

	.t-show-for-medium-up[class] {
		display:inherit;
	}
	.t-show-for-medium-up-block[class] {
		display:block;
	}
}