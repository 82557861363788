/* =============================================================================
 * Button
 *
 * 1. Needed to reset any styles set on a:visited.
 * TODO:Hover effects
============================================================================= */

.c-button,
.c-button:visited /* [1] */ {
	@mixin text-style-reset;
	-ms-flex-align:center;
	align-items:center;
	background-color:transparent;
	border-width:6px;
	border-style:solid;
	border-color:transparent;
	border-radius:var(--spacing-ml);
	color:var(--color-black);
	cursor:pointer;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-ms-flex-pack:center;
	justify-content:center;
	margin:5px 0;
	overflow:visible;
	outline:none;
	padding:var(--spacing) var(--spacing-sm);
	text-decoration:underline;
	transition:var(--transition);
	width:auto;
}

@media(--sm) {
	.c-button {
		margin:0;
	}

	.c-button + .c-button {
		margin-left: var(--spacing-s);
	}

	.c-button + .c-button--primary {
		margin-left: var(--spacing-sm);
	}

}

.c-button--small {
	font-size:0.792em;
}

.c-button[disabled] {
	background-color:var(--color-light-grey);
	border:none;
	cursor:not-allowed;
}

@media(--md) {
	.c-button,
	.c-button:visited {
		padding:var(--spacing) var(--spacing-ml);
	}
}

/* Primary
 ---------------------------------------------------------------------------- */
.c-button--primary,
.c-button--primary:visited {
	background-color:var(--color-grass);
	color:var(--color-white);
	text-decoration:none;
	border:none;
	box-shadow:0 0 0 6px rgb(211, 236, 200);
	font-weight:bold;
	margin:0 6px;
}

.c-button--primary:hover {
	background-color:var(--color-darkgrass);
	color:var(--color-white);
	box-shadow:none;
	text-decoration:none;
}

.c-button--primary[disabled] {
	background:var(--color-light-grey);
	box-shadow:0 0 0 6px rgb(241, 241, 241);
	color:var(--color-black);
}

/* Secondary
 ---------------------------------------------------------------------------- */
.c-button.c-button--secondary,
.c-button.c-button--secondary:visited {
	box-shadow:0 0 0 2px var(--color-light-grey-green) inset;
	color:var(--color-grass);
	text-decoration:none;
}

.c-button.c-button--secondary:hover {
	background-color:var(--color-white);
	background-clip:padding-box;
	box-shadow:none;
	text-decoration:none;
}

.c-button--secondary[disabled],
.c-button--secondary[disabled]:hover {
	background:var(--color-light-grey);
	color:var(--color-black);
	box-shadow:0 0 0 2px rgb(241, 241, 241);
}

/* Primary inverted
 ---------------------------------------------------------------------------- */
.c-button--primary-inverted,
.c-button--primary-inverted:visited {
	color:var(--color-grass);
	background-color:var(--color-white);
	border:none;
	box-shadow:0 0 0 6px rgba(255, 255, 255, 0.3);
	font-weight:bold;
	text-decoration:none;
	margin:0 6px;
}

.c-button--primary-inverted:hover {
	background-color:var(--color-light-grey-green);
	box-shadow:none;
	text-decoration:none;
}

/* Inverted secondary
 ---------------------------------------------------------------------------- */
.c-button--secondary-inverted,
.c-button--secondary-inverted:visited {
	color:var(--color-white);
	box-shadow:0 0 0 2px var(--color-white) inset;
	text-decoration:none;
	text-shadow:0 0 24px rgba(0, 0, 0);
}

.c-button--secondary-inverted:hover {
	background-color:var(--color-white);
	background-clip:padding-box;
	color:var(--color-grass);
	box-shadow:none;
	text-decoration:none;
}

/* Plus
---------------------------------------------------------------------------- */
.c-button--plus {
	padding:var(--spacing-s);
	box-shadow:0 0 0 2px var(--color-light-grey-green) inset;
	position:relative;
}

.c-button--plus::before,
.c-button--plus::after {
	content:"";
	display:block;
	width:calc(100% / 2 - 2px);
	height:2px;
	background:var(--color-grass);
	position:absolute;
	top:50%;
	left:50%;
	-ms-transform:translate(-50%, -50%);
	transform:translate(-50%, -50%);
}

.c-button--plus::after {
	width:2px;
	height:calc(100% / 2 - 2px);
}

@media(--md) {
	.c-button--plus {
		padding:var(--spacing-sm);
	}
}

/* Complentary
 ---------------------------------------------------------------------------- */
.c-button--complentary,
.c-button--complentary:visited {
	color:var(--color-grass);
	text-decoration:none;
}

/* Petite
 ---------------------------------------------------------------------------- */
.c-button--petite {
	padding:var(--spacing-xs) var(--spacing-s);
}

@media(--md) {
	.c-button--petite {
		padding:var(--spacing) var(--spacing-sm);
	}
}

/* Silent
 ---------------------------------------------------------------------------- */
.c-button--silent {
	background:var(--color-battleship-grey);
	border-width:6px;
	border-color:#e2e2e2;
	color:var(--color-white);
	text-decoration:none;
}

.c-button--silent:hover {
	color:var(--color-white);
}

/* Round
---------------------------------------------------------------------------- */
.c-button--round {
	padding:0;
	width:3.529em;
	height:3.529em;
	border-radius:50%/50%;
}

/* Icon
 ---------------------------------------------------------------------------- */
.c-button .c-button__icon {
	width:1.647em;
	height:1.647em;
}

.c-button__icon + .c-button__text,
.c-button__text + .c-button__icon {
	padding-left:0.471em;
}

/* Link Style
 ---------------------------------------------------------------------------- */
.c-button--linkstyle {
	padding: 14px 0;
	color:var(--color-grass);
	text-decoration:none;
	transition:color 0.15s ease;
	border:0;
}

@media(--md) {
	.c-button--linkstyle,
	.c-button--linkstyle:visited {
		padding: 14px 0px;
	}
}

.c-button--linkstyle:visited {
	color:var(--color-grass);
}

.c-button--linkstyle:hover {
	text-decoration:none;
	color:var(--color-darkgrass);
}

.c-button--linkstyle__secondary {
	color:var(--color-red);
}

.c-button--linkstyle__secondary:hover {
	color:var(--color-darkred);
}

/* Block, reset
 ---------------------------------------------------------------------------- */
.c-button--block {
	width:100%;
}

.c-button--reset {
	padding:0;
}

/* Less horizontal padding
 ---------------------------------------------------------------------------- */
.c-button--tight {
	padding:var(--spacing) var(--spacing-sm);
}

/* Left padding on button title
 ---------------------------------------------------------------------------- */
.c-button__title {
	padding-left:0.471em;
}
