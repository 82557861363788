.range__container {
  display: flex;
  flex-direction: column;
}

.range__label {
  font-weight: 500;
}

.range {
  position: relative;
  margin-top: var(--spacing-s);
}

.range input {
  width: 100%;
  position: absolute;
  -webkit-appearance: none;
  padding: 0.5em 0;
}

.range input::-moz-range-thumb {
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: #4ba925;
  cursor: pointer;
  border: 0 !important;
}

.range input::-ms-thumb {
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: #4ba925;
  cursor: pointer;
  border: 0 !important;
}

.range input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: #4ba925;
  cursor: pointer;
  border: 0 !important;
}

.range input::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #4ba925;
}
.range input::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #4ba925;
}

.range input::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #4ba925;
}

.range input:focus {
  background: none;
  outline: none;
}

.range input::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.range__labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0;
  list-style: none;
  position: relative;
  pointer-events: none;
}

.range__labels li {
  position: relative;
  color: #4ba925;
  font-weight: 500;
  cursor: pointer;
  padding: 0 6px;
  width: 22px;
}

.range__labels li::before {
  position: absolute;
  top: -18px;
  right: 0;
  left: 0;
  content: "";
  margin: 0 auto;
  width: 11px;
  height: 11px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #4ba925;
}

.range__labels li:last-child:before {
  left: unset;
}

.active {
  color: #4ba925;
}

.selected::before {
  background: #4ba925 !important;
}

/* Is needed to be able to click on entire thumb, otherwise the li-element is in the way*/
.active.selected::before {
  display: none;
}
