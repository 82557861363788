/* =============================================================================
 * Margins
============================================================================= */

.t-main-content-margin[class] {
	@mixin main-content-margin;
}

/* Bottom */

.t-margin-bottom-none[class] {
	margin-bottom:0;
}

.t-margin-bottom-main[class] {
	margin-bottom:var(--spacing);
}

.t-margin-bottom-xs[class] {
	margin-bottom:var(--spacing-xs);
}

.t-margin-bottom-s[class] {
	margin-bottom:var(--spacing-s);
}

.t-margin-bottom-sm[class] {
	margin-bottom:var(--spacing-sm);
}

.t-margin-bottom-m[class] {
	margin-bottom:var(--spacing-m);
}

.t-margin-bottom-ml[class] {
	margin-bottom:var(--spacing-ml);
}

.t-margin-bottom-l[class] {
	margin-bottom:var(--spacing-l);
}

.t-margin-bottom-xxl[class] {
	margin-bottom:var(--spacing-xxl);
}

/* Right */

.t-margin-top-none[class] {
	margin-top:0;
}

.t-margin-top-main[class] {
	margin-top:var(--spacing);
}

.t-margin-right-s[class] {
	margin-right:var(--spacing-s);
}

.t-md-margin-right-m[class] {
	@mixin md-margin-right-m;
}

.t-margin-right-ml[class] {
	margin-right:var(--spacing-ml);
}

/* Top */
.t-margin-top-xs[class] {
	margin-top: var(--spacing-xs);
}

.t-margin-top-s[class] {
	margin-top:var(--spacing-s);
}

.t-margin-top-sm[class] {
	margin-top:var(--spacing-sm);
}

.t-margin-top-m[class] {
	margin-top:var(--spacing-m);
}

.t-margin-top-ml[class] {
	margin-top:var(--spacing-ml);
}

.t-margin-top-l[class] {
	margin-top:var(--spacing-l);
}

/* Left */

.t-margin-left-xs[class] {
	margin-left: var(--spacing-xs);
}

.t-margin-left-s[class] {
	margin-left:var(--spacing-s);
}

.t-margin-left-m[class] {
	margin-left:var(--spacing-m);
}

@media(--s) {
	.t-margin-top-s\@s[class] {
		margin-top:var(--spacing-s);
	}
}