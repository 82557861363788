/* =============================================================================
 * Skip link
============================================================================= */
.c-skip {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-fixed-element);
  transform: translateY(-100%);
  transition: transform 200ms ease-in-out;
}

.c-skip:focus,
.c-skip:focus-within {
  padding: 20px 0;
  background: #000;
  color: #fff;
  text-align: center;
  transform: translateY(0);
}

.c-skip__link {
	color: inherit;
	text-decoration: none;
	outline: none;
}

.c-skip__link:focus {
	text-decoration: underline;
}