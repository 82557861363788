.c-radio {
  margin-bottom: 12px;
}

.c-radio--inline {
  display: inline-block;
  margin-right: 48px;
}

.c-radio__input {
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.c-radio__label {
  display: inline-block;
  position: relative;
  padding: 7px 0 7px 48px;
  vertical-align: middle;
  white-space: normal;
}

.c-radio__heading {
  font-weight: 700;
  text-transform: none;
  margin-bottom: 12px;
}

.c-radio__label::after {
  @mixin box-shadow 0.05;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  width: 36px;
  height: 36px;
  border: 1px solid var(--color-grass);
  border-radius: 50%;
  background: #fff;
  content: "";
  text-align: center;
  transition: var(--transition);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.c-radio__label:hover::after {
  border-color: var(--color-light-grey-green);
}

.c-radio__input:checked + .c-radio__label::after {
  border-color: var(--color-light-grey-green);
}

.c-radio__input:checked + .c-radio__label:hover::after {
  border-color: var(--color-grass);
}

.c-radio__input:checked + .c-radio__label::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 19px;
  z-index: 2;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--color-grass);
  content: "";
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.c-radio__input[disabled] + .c-radio__label::after {
  background-color: var(--color-light-grey);
  border: none;
  cursor: not-allowed;
}

@media (--md) {
  .c-radio__heading {
    margin-bottom: 24px;
  }
}
