/* =============================================================================
 * Icon
============================================================================= */

/* Import Grunticon generated files
--------------------------------------------------------------------------- */
.c-icon {
	display:inline-block;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-ms-flex-direction:row;
	flex-direction:row;
	-ms-flex-pack:center;
	justify-content:center;
	-ms-flex-align:center;
	align-items:center;
	width:1em;
	height:1em;
	vertical-align:middle;
	text-align:center;
	text-indent:0;
	transition-property:fill, background;
	fill:currentColor;
}

.c-icon > svg {
	color:currentColor;
	fill:currentColor;
	display:inline-block;
	max-height:100%;
	vertical-align:middle;
}

.c-icon path {
	transition-property:fill;
}

/*
 * Overrides for incorrectly created SVG files
 */
.c-icon--zip > svg {
	stroke:currentColor;
}

/* Circle – Show icon on a circle
--------------------------------------------------------------------------- */
.c-icon--circle {
	border-radius:50%;
	background:currentColor;
	fill:#fff;
}

.c-icon--circle.c-icon--circle-hollow {
	border-width:1px;
	border-style:solid;
	border-color:currentcolor;
	color:var(--color-grass);
	background:transparent;
	fill:currentcolor;
}

.c-icon--circle.c-icon--circle-hollow > svg {
	width:80%;
	height:80%;
	margin:auto;
}


/* Sizes
--------------------------------------------------------------------------- */
.c-icon--10px > svg,
.c-icon--10px {
	width:10px;
	height:10px;
}

.c-icon--12px > svg,
.c-icon--12px {
	width:12px;
	height:12px;
}

.c-icon--16px > svg,
.c-icon--16px {
	width:16px;
	height:16px;
}


.c-icon--18px > svg,
.c-icon--18px {
	width:18px;
	height:18px;
}

.c-icon--20px > svg,
.c-icon--20px {
	width:20px;
	height:20px;
}

.c-icon--24px > svg,
.c-icon--24px {
	width:24px;
	height:24px;
}

.c-icon--28px > svg,
.c-icon--28px {
	width:24px;
	height:24px;
}

.c-icon--48px > svg,
.c-icon--48px {
	width:48px;
	height:48px;
}
