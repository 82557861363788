.s-print {
	font-size:10pt;
	line-height:1.3;
}

.s-print .front {
	/* background-color:var(--color-prussian-blue);
	color:var(--color-white); */
	page-break-after:always;
}

.s-print h1 {
	font-size:16pt;
	font-weight:500;
	text-transform:uppercase;
	margin-bottom:1cm;
}

.s-print .front h1 {
	font-size:30pt;
	margin-top:2cm;
	text-align:center;
}

.s-print .front h1:first-of-type {
	margin-top:3cm;
}

.s-print h2 {
	font-size:13pt;
	font-weight:500;
	margin:0.5cm 0 0.1cm;
}

.s-print .front h2 {
	font-size:16pt;
	margin:1cm 0 2cm;
	text-align:center;
}

.s-print h3 {
	font-size:11pt;
	margin:0.3cm 0 0.2cm;
	font-weight:500;
	font-style:italic;
}

.s-print h4,
.s-print small {
	font-size:8pt;
	line-height:1.2;
}

.s-print ul {
	list-style:disc outside none;
	margin-left:18px;
}

.s-print ol {
	list-style:decimal;
	margin-left:18px;
}

.s-print dt {
	margin-bottom:0.1cm;
	margin-right:2cm;
	float:left;
	font-weight:500;
	width:20%;
}

.s-print dd {
	margin-bottom:0.1cm;
}

.s-print span + small {
	display:block;
	margin-bottom:0.2cm;
}

.s-print p {
	margin-bottom:0.2cm;
}

.s-print p small {
	display:block;
}


.s-print .front p {
	margin-top:3cm;
}

.s-print h5 {
	display:block;
	font-size:10pt;
	font-weight:500;
	margin:0.3cm 0 0.2cm;
}

.s-print em {
	font-style:italic;
	margin-bottom:0.2cm;
}

.s-print strong {
	font-weight:500;
}

.s-print ul.checkbox-list {
	list-style:none;
	margin:0.3cm 0 0.4cm;
}

.s-print .checkbox-list li {
	position:relative;
	list-style:none;
	margin-bottom:0.3cm;
	padding-left:0.75cm;
	padding-top:0.1cm;
}

.s-print .checkbox-list li::before {
	position:absolute;
	top:1px;
	left:0;
	width:0.5cm;
	height:0.5cm;
	border:1px solid #000;
	content:"";
}

.s-print .number {
	margin-right:0.2cm;
}

.s-print.egenkontroll-program h1 {
	margin:0.7cm 0 0.5cm;
}

.s-print table {
	border-collapse:separate;
	border-spacing:0 0.2cm;
}

.s-print th {
	font-weight:500;
}

.s-print th tr {
	padding-bottom:0.2cm;
}

.s-print tr {
	padding-bottom:0.1cm;
}

.s-print td {
	padding-right:0.2cm;
}
