.dialog {
	--container-max-width: 992px; 
	display: grid;
	width: 100%;
	height: 100%;
	max-height: 100%;
	background-color: #fff;
	grid-template-columns: 100%;
	grid-template-rows: auto 1fr auto;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 1px 2px 0px rgb(0, 0, 0, 0.12);
}

.dialog__header {
	padding: 24px 24px 0;
}

.dialog__header-title {
	font-size: 24px;
	margin: 0 0 16px;
}

.dialog__header-subtitle {
	font-size: 16px;
	font-weight: 700;
	margin: 0;
}

.dialog__body {
	padding: 24px;
}

.dialog__footer {
	display: flex;
	flex-direction: row;
	padding: 0 24px 24px;
	margin-top: 24px;
}

.dialog__footer--end {
	justify-content: flex-end;
}

@media(--md) {

	.dialog__header,
	.dialog__body,
	.dialog__footer {
		padding-left: 40px;
		padding-right: 40px;
	}

}