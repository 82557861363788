@define-mixin underline-link {
	border-bottom:1px solid rgba(255, 255, 255, 0.3);
}

@define-mixin reset-underline-link {
	border-bottom:0;
}

.c-link--silent {
	color:var(--color-battleship-grey);
}

.c-link--underline {
	@mixin underline-link;
}

.c-link--no-underline {
	@mixin reset-underline-link;
}