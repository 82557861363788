/* =============================================================================
 * Header
============================================================================= */
.c-header {
  color: #fff;
  z-index: var(--z-index-header);
  top: 0;
}

.c-header__inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: var(--spacing-s);
  padding-top: var(--spacing-s);
  position: relative;
}

.c-header__inner::after {
  content: "";
  bottom: -6px;
  display: block;
  height: 6px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.c-header__search {
  width: 100%;
  display: none;
}

.c-header__search .c-search-form {
  z-index: 18;
}

.c-header__search.open {
  display: block;
}

.c-header__search-tip {
  display: none;
}

.c-header__menu-icon,
.c-header__menu-icon:visited {
  color: var(--color-grass);
  text-align: right;
}

.c-header__login-text {
  white-space: nowrap;
}

.c-mobile-logo {
  display: block;
  background: var(--color-grass);
  padding: 1em;
}

.c-mobile-logo .c-logo__image svg {
  fill: #fff;
}

.c-mobile-logo .c-logo__text {
  color: #fff;
}

.c-header__profile-icon {
  height: 100%;
}

.c-header__inner .jsPopIn {
  color: #fff;
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-header__profile-icon .jsPopIn {
  display: block;
  padding: var(--spacing-s) var(--spacing-sm);
}

.c-header__profile-icon .jsExpanderIcon {
  display: none;
}

@media (--below-md) {
  .c-header__inner {
    padding: 0;
    border-top: solid 1px;
    border-top: 1px solid #fff;
    border-bottom: 1px #fff;
    max-height: 63px;
  }

  .c-header__search {
    background: var(--color-grass);
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.75);
    padding: 35px 25px 0;
    position: absolute;
    bottom: -146px;
    height: 145px;
    left: 0;
    max-width: 100%;
    z-index: 1002;
  }

  .c-header__search-tip {
    display: block;
    color: #fff;
    margin-top: 0.5em;
  }
}

@media (--md) {
  .c-header {
    position: static;
    background: var(--color-main-background);
  }

  .c-header__inner::after,
  .c-header__menu-icon,
  .c-header__login-icon,
  .c-header__search-icon,
  .c-header__profile-icon,
  .c-mobile-logo {
    display: none;
  }

  .c-header__search {
    display: block;
    max-width: 320px;
  }

  .c-header__inner {
    padding-bottom: var(--spacing-m);
    padding-top: var(--spacing-m);
  }
}
