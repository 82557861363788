.c-output--label {
	@mixin text-style-zeta;
	color:var(--color-battleship-grey);
}

.c-output--list {
	padding-left:1rem;
}

.c-output--listitem {
	padding-left:1rem;
}

