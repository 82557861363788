@for $i from 1 to 12 {
	.t-width-$i {
		width:calc(100% * $(i)/12) !important;
	}
}

@media(--below-md) {
	@for $i from 1 to 12 {
		.t-width-$i\@below-md {
			width:calc(100% * $(i)/12) !important;
		}
	}
}

@media(--s) {
	@for $i from 1 to 12 {
		.t-width-$i\@s {
			width:calc(100% * $(i)/12) !important;
		}
	}
}