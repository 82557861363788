.c-message {
  background: var(--color-light-grey-green);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.c-message--narrow {
  @media (--md) {
    margin-left: 80px;
    margin-right: 40px;
  }
}

.c-message--error {
  background: var(--color-dusty-orange);
}

.c-message--sticky {
  position: fixed;
  width: 100%;
  z-index: 200;
}

.c-message.c-message--error-inverted {
  background: var(--color-white);
  color: var(--color-dusty-orange);
}

.c-message--success {
  background: var(--color-light-grey-green);
}

.c-message--info {
  background: var(--color-battleship-grey);
}

.c-message + .c-message {
  margin-top: 12px;
}

.c-message__inner {
  padding: 18px 10px;
  width: 90%;
}

.c-message__text {
  font-weight: 500;
}

.c-message,
.c-message a,
.c-message a:visited {
  color: rgb(0, 0, 0);
}

.c-message a {
  text-decoration: underline;
}

.c-message a:hover,
.c-message a:focus {
  color: rgba(0, 0, 0, 0.6);
}

.c-message__close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
}

/* Message inline
 ---------------------------------------------------------------------------- */
.c-message--inline .c-message__inner {
  display: flex;
  align-items: center;
  padding: 18px 18px;
}

.c-message--inline .c-message__text {
  text-align: left;
}

.c-message__body-text {
  font-weight: 400;
}
/*--------------------------------------------------------------------------- */

@media (--md) {
  .c-message__inner {
    width: auto;
    max-width: 95%;
    text-align: center;
  }
}
