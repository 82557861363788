/* =============================================================================
 * Text scope
 *
 * Adds styling to elements when inside the scope .s-text, e.g. margin
 * or another font-weight than 'normal'.
============================================================================= */

/* Margins
 * Weird syntax for clarity's sake.
 ---------------------------------------------------------------------------- */

/* .s-text h1,
h1.s-text {
	margin-bottom:42px;
}
*/

.s-text h2,
h2.s-text,
.s-text h3,
h3.s-text,
.s-text h4,
h4.s-text,
.s-text h5,
h5.s-text,
.s-text h6,
h6.s-text {
	margin-bottom:var(--spacing-xs);
	@media(--md) {
		margin-bottom:var(--spacing-s);
	}
}

.s-text ul,
ul.s-text,
.s-text ol,
ol.s-text,
.s-text dl,
dl.s-text,
.s-text blockquote,
blockquote.s-text,
.s-text address,
address.s-text,
.s-text hr,
hr.s-text,
.s-text table,
table.s-text,
.s-text fieldset,
fieldset.s-text,
.s-text figure,
figure.s-text,
.s-text pre,
pre.s-text {
	margin-bottom:var(--spacing-s);
	@media(--md) {
		margin-bottom:var(--spacing-m);
	}
}

.s-text .preamble {
	@mixin leading-dash var(--color-light-grey-green);
	margin-top:var(--spacing-m);
}

.s-text .preamble p,
.s-text.preamble p {
	@mixin text-style-beta;
	margin-bottom:var(--spacing-m);
}

.s-text .preamble p:last-of-type,
.s-text.preamble p:last-of-type {
	margin-bottom:0px;
}

.s-text .preamble p a,
.s-text.preamble p a {
	text-decoration:underline;
}

.s-text .preamble p a:hover,
.s-text .preamble p a:focus,
.s-text.preamble p a:hover,
.s-text.preamble p a:focus {
	text-decoration:none;
}

.s-text p,
p.s-text {
	margin-bottom:var(--spacing-s);
	word-wrap:break-word;
	@media(--md) {
		margin-bottom:var(--spacing-sm);
	}
}

.s-text > *:last-child {
	margin-bottom:0;
}

.s-text .two-columns {
	columns:2 258px;
	margin-bottom:60px;
	column-gap:40px;
	@media(--md) {
		width:calc(100% * 8/6);
	}
}

.s-text .two-columns p {
	width:100%;
}

.s-text ul:last-of-type li:last-of-type,
.s-text dl:last-of-type dd:last-of-type {
	margin-bottom:0;
}

.c-accordion .s-text ul:last-child,
.c-accordion .s-text dl:last-child,
.c-accordion .s-text ol:last-child,
.c-accordion .s-text p:last-child {
	margin-bottom:0px;
}

/* Headings
 ---------------------------------------------------------------------------- */
.s-text h1,
h1.s-text {
	@mixin text-style-alpha;
}

.s-text h2,
h2.s-text {
	@mixin text-style-beta;
}

.s-text h3,
h3.s-text {
	@mixin text-style-delta;
}

.s-text h4,
h4.s-text {
	font-weight:700;
}


/* Inline typography
 ---------------------------------------------------------------------------- */
.s-text i,
i.s-text,
.s-text em,
em.s-text {
	font-style:italic;
}

.s-text b,
b.s-text,
.s-text strong,
strong.s-text {
	font-weight:700;
}


/* Lists
 *
 *
 ---------------------------------------------------------------------------- */
.s-text ul,
ul.s-text {
	list-style:none;
}

.s-text ol,
ol.s-text {
	list-style-type:none;
	counter-reset:li;
}

.s-text ol > li,
ol.s-text > li {
	counter-increment:li;
}

.s-text ol > li::before,
ol.s-text > li::before {
	content:counter(li)".";
	display:inline-block;
	width:1em;
	font-weight:bold;
}

.s-text ul:not(.t-unstyled-list),
ul:not(.t-unstyled-list).s-text,
.s-text ol,
ol.s-text {
	margin-left:var(--spacing-sm);
}

.s-text ul:not(.t-unstyled-list) li {
	margin-bottom:var(--spacing);
	position:relative;
}

.s-text ul:not(.t-unstyled-list) li::before,
ul.s-text ul:not(.t-unstyled-list) li::before {
	background-color:var(--color-light-grey-green);
	width:6px;
	height:6px;
	content:"";
	position:absolute;
	top:8px;
	left:-var(--spacing-sm);
}

.s-text ul li ol li::before,
ul.s-text li ol li::before {
	display:none;
}

.s-text ul:not(.t-unstyled-list) li > ul,
.s-text li > ol {
	@mixin reset-gradient-box;
	margin-bottom:var(--spacing);
	margin-top:var(--spacing);
	padding-bottom:0;
	padding-top:0;
}

.s-text dt {
	font-weight:700;
}

.s-text dd {
	margin-bottom:var(--spacing);
}

/* Fix for accordions inside s-text containers */
.s-text .c-accordion {
	margin-left:0;
}

.s-text .c-accordion__fold::before {
	display:none;
}

/* Tables
 *
 * 1. To counter-act top padding on th
 ---------------------------------------------------------------------------- */
.s-text table,
table.s-text {
	margin-top:var(--spacing-ml);
}

.s-text caption,
caption.s-text {
	padding-bottom:var(--spacing-sm);
	border-bottom:1px solid var(--color-light-grey);
}

.s-text th,
th.s-text {
	@mixin text-style-mu;
	color:var(--color-battleship-grey);
}

.s-text td,
td.s-text,
.s-text th,
th.s-text {
	padding:var(--spacing) var(--spacing-sm) var(--spacing) 0;
	border-bottom:1px solid var(--color-light-grey);
}

.s-text td:first-child,
td.s-text:first-child,
.s-text th:first-child,
th.s-text:first-child {
	padding-left:var(--spacing-sm);
}

.s-text tfoot,
tfoot.s-text {
	font-weight:bold;
}

/* Key value list with table */

table.o-key-value-list th:first-of-type {
	width:50%;
}

@media(--s) {
	.s-text table,
	table.s-text {
		border-collapse:collapse;
		border-spacing:0;
		width:100%;
	}

	.s-text td,
	td.s-text,
	.s-text th,
	th.s-text {
		padding:var(--spacing-s);
	}
}

/* Variant two of styling of paragraphs in scopes
 *
 *
 ---------------------------------------------------------------------------- */
.s-text-2 p,
p.s-text-2 {
	margin-bottom:12px;
	word-wrap:break-word;
}

.s-text-2 p:last-of-type,
p.s-text-2:last-of-type {
	margin-bottom:15px;
}

.s-text-2 p a,
p.s-text-2 a {
	color:var(--color-black);
	text-decoration:underline;
}

.s-text-2 p a:hover,
.s-text-2 p a:focus,
p.s-text-2 a:hover,
p.s-text-2 a:focus {
	text-decoration:none;
}

@media(--md) {
	.s-text-2 p:last-of-type,
	p.s-text-2:last-of-type {
		margin-bottom:30px;
	}
}

/* Media
 *
 *
 ---------------------------------------------------------------------------- */

.s-text .o-media {
	margin-bottom:0.5em;
}

/* Highlighted text
 *
 *
 ---------------------------------------------------------------------------- */
 .highlighted[class] {
	 background-color:var(--color-light-grey-green);
	 display:inline-block;
	 padding:0 4px;
	 margin:0;
 }