body {
	@mixin text-style-reset;
	-moz-osx-font-smoothing:greyscale;
	-webkit-font-smoothing:antialiased;
	background:var(--color-main-background);
	min-height:100%;
	overflow-x:hidden;
	position:relative;
	display: flex;
    flex-direction: column;
}

body.taken {
	overflow:hidden;
}