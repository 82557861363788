.c-form-buttons {
	border-top:1px solid var(--color-light-grey);
	padding-top:var(--spacing-m);
	text-align:right;
}

.c-form-buttons.c-form-buttons--tight-top {
	margin-top:0;
}

.c-form-buttons__button--bare {
	padding:18px 0 0;
	text-align:right;
}

@media(--md) {
	.c-form-buttons {
		border-top:1px solid var(--color-light-grey);
		padding-top:var(--spacing-m);
	}

	.c-form-buttons__button--bare {
		padding-top:var(--spacing-m);
	}
}


@media(--sm) {
	.c-form-buttons__button {
		display:inline-block;
		margin-left:var(--spacing-m);
	}
}