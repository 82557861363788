/* =============================================================================
 * colors
============================================================================= */
.t-color-battleship-grey[class],
.t-color-battleship-grey[class] p {
	color:var(--color-battleship-grey);
}

.-white[class],
.t-color-white[class] p {
	color:var(--color-white);
}

.t-color-blue[class],
.t-color-blue[class] p {
	color:var(--color-prussian-blue);
}

.t-color-black[class] {
	color:var(--color-black);
}

.t-color-orange[class] {
	color:var(--color-dusty-orange);
}

.t-color-green-grass[class] {
	color:var(--color-grass);
}

.t-background-beige-yellow[class] {
	background-color:var(--color-beige-yellow);
}

.t-background-orange[class] {
	background-color:var(--color-dusty-orange);
}

.t-background-turtle-green[class] {
	background-color:var(--color-turtle-green);
}

.t-background-white[class] {
	background-color:var(--color-white);
}

.t-border-beige-yellow[class] {
	border-color: var(--color-beige-yellow);
}

.t-border-green[class] {
	border:1px solid var(--color-turtle-green);
}
.t-background-white-grey[class] {
	background-color:var(--color-white-grey);
}
.t-background-light-grey[class] {
	background-color:var(--color-light-grey);
}
.t-background-cold-grey[class] {
	background-color: var(--color-cold-grey);
}