/* =============================================================================
 * Floats
============================================================================= */
.t-float-left {
	float:left;
}

.t-float-right {
	float:right;
}
