/* =============================================================================
 * Visually hidden
 *
 * Hidden visually, but screen readers will read it.
============================================================================= */
.t-visually-hidden,
.js .t-visually-hidden--js {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.t-visually-hidden--focusable:active,
.t-visually-hidden--focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  margin: 0;
}
