/* =============================================================================
 * Checkbox
 *
 * 1. Adds 1px on top and bottom padding to avoid jumpy behaviour when checked
============================================================================= */

.c-checkbox {
	margin-bottom:var(--spacing);
}

.c-checkbox__heading {
	font-weight:700;
	text-transform:none;
	margin-bottom:var(--spacing-sm);
}

.c-checkbox--inline {
	display:inline-block;
	margin-right:var(--spacing-ml);
}

.c-checkbox-group__header {
	border-bottom:1px solid var(--color-light-grey);
	margin-bottom:var(--spacing);
	padding-bottom:var(--spacing);
}

.c-checkbox__input,
.c-checkbox-group__header__input {
	position:absolute;
	opacity:0;
}

.c-checkbox-group {
	margin-top:var(--spacing-s);
}

.c-checkbox-group:first-child {
	margin-top:0;
}

.c-checkbox-group__header__label {
	color:var(--color-grass);
}

.c-checkbox__label-extra {
	display:inline-block;
	margin-left:0.5em;
}

.c-checkbox__label-extra .c-icon {
	margin-right:3px;
}

.c-checkbox__label-extra .c-icon--circle {
	vertical-align:baseline;
}

.c-checkbox__label-extra-text {
	color:#767676;
	font-style:italic;
	font-size:0.8em;
}

.c-checkbox__label,
.c-checkbox-group__header__label {
	display:inline-block;
	position:relative;
	padding:calc(var(--spacing-xs) + 1px) 0 calc(var(--spacing-xs) + 1px) var(--spacing-ml); /* [1] */
	vertical-align:middle;
	white-space:normal;
	width:100%;
}

.c-checkbox__label::after,
.c-checkbox-group__header__label::after {
	@mixin box-shadow 0.05;
	box-sizing:border-box;
	position:absolute;
	top:50%;
	left:0;
	width:32px;
	height:32px;
	border:1px solid var(--color-grass);
	background:#fff;
	line-height:18px;
	content:"";
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
}

.c-checkbox__label:hover::after,
.c-checkbox-group__header__label:hover::after,
.c-checkbox__input:focus + .c-checkbox__label::after,
.c-checkbox__input:focus + .c-checkbox-group__header__label::after {
	border-color:var(--color-light-grey-green);
}

.c-checkbox__input:checked + .c-checkbox__label::after,
.c-checkbox-group__header__input:checked + .c-checkbox-group__header__label::after {
	background-image:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNDJweCIgaGVpZ2h0PSI0MnB4IiB2aWV3Qm94PSIwIDAgNDIgNDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQyIDQyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjx0aXRsZT5pY29uLWNoZWNrYm94LTMyPC90aXRsZT4NCjxnPg0KCTxkZWZzPg0KCQk8cmVjdCBpZD0iU1ZHSURfMV8iIHg9IjUiIHk9IjUiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIvPg0KCTwvZGVmcz4NCgk8Y2xpcFBhdGggaWQ9IlNWR0lEXzJfIj4NCgkJPHVzZSB4bGluazpocmVmPSIjU1ZHSURfMV8iICBvdmVyZmxvdz0idmlzaWJsZSIvPg0KCTwvY2xpcFBhdGg+DQoJPGcgY2xpcC1wYXRoPSJ1cmwoI1NWR0lEXzJfKSI+DQoJCTxyZWN0IGZpbGw9Im5vbmUiIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiIvPg0KCTwvZz4NCjwvZz4NCjxwb2x5Z29uIGZpbGw9IiM0QkE5MjUiIHBvaW50cz0iMTgsMjcuNDE0IDEyLjU4NiwyMiAxNCwyMC41ODYgMTgsMjQuNTg2IDI4LDE0LjU4NiAyOS40MTQsMTYgIi8+DQo8L3N2Zz4NCg==);
	background-position:-5px;
	border-color:var(--color-light-grey-green);
}

.c-checkbox__input:checked + .c-checkbox__label:hover::after,
.c-checkbox-group__header__input:checked + .c-checkbox-group__header__label:hover::after,
.c-checkbox__input:checked:focus + .c-checkbox__label::after,
.c-checkbox-group__header__input:checked:focus + .c-checkbox-group__header__label::after {
	border-color:var(--color-grass);
}

.c-checkbox-group__checkboxes-wrap {
	margin-bottom:var(--spacing-l);
}

.c-checkbox-group__field {
	width:80%;
	margin-bottom:var(--spacing-sm);
	margin-top:var(--spacing-xs);
}

.c-checkbox-group__field--tiny {
	width:15%;
	min-width:63px;
}

.c-checkbox-group__option-field {
	margin-bottom:var(--spacing-sm);
}

.c-checkbox-group__add-button {
	text-align:right;
}

.c-checkbox-group__column.jsCheckboxGroupColumn + .c-checkbox-group__fields .c-checkbox-group__field {
	display:none;
}

.c-checkbox-group__column.c-checkbox-group__column--checked + .c-checkbox-group__fields .c-checkbox-group__field {
	display:block;
}

/* Disabled */
.c-checkbox__input.disabled + .c-checkbox__label::after,
.c-checkbox__input-disabled + .c-checkbox-group__header__label::after,
.c-checkbox__input.disabled[disabled] + .c-checkbox__label::after,
.c-checkbox__input-disabled[disabled] + .c-checkbox-group__header__label::after {
	background-color:var(--color-light-grey);
	border:none;
	cursor:not-allowed;
}

@media(--md) {
	.c-checkbox-group {
		margin-top:var(--spacing-sm);
	}

	.c-checkbox-group__field--bigger {
		-ms-flex:1 1 100%;
		flex:1 1 100%;
	}

	.c-checkbox-group:first-child {
		margin-top:0;
	}

	.c-checkbox-group__field {
		margin-bottom:var(--spacing);
		margin-top:0;
	}

	.c-checkbox-group__option-field {
		margin-bottom:0;
	}

	.c-checkbox-group__add-button {
		text-align:left;
		margin-top:var(--spacing-xs);
		margin-left:-var(--spacing-s);
	}
}

@media(--lg) {
	.c-checkbox-group__field--bigger {
		-ms-flex:none;
		flex:none;
	}
}

@media(--xl) {
	.c-checkbox-group__field--bigger {
		-ms-flex:none;
		flex:none;
	}
}
