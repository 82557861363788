/* =============================================================================
 * Links
============================================================================= */
a {
	color:var(--color-grass);
	text-decoration:none;
	transition:color 0.15s ease;
}

a:hover {
	text-decoration:none;
	color:var(--color-darkgrass);
}

.s-grass a {
	color:var(--color-white);
}