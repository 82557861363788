@define-mixin buffer-box {
	padding:var(--spacing-sm) 0 var(--spacing-sm);
	border:0 solid var(--color-light-grey);
	@media(--md) {
		padding:var(--spacing-ml) 0 var(--spacing-ml);
	}
}

.c-buffer-box {
	@mixin buffer-box;
}

/* Disable padding for certain directions
---------------------------------------------------------------------------- */
.c-buffer-box--no-top {
	padding-top:0;
}

.c-buffer-box--no-bottom {
	padding-bottom:0;
}

.c-buffer-box--no-padding {
	padding:0;
}

/* Set padding for left, right or both.
---------------------------------------------------------------------------- */
.c-buffer-box--left {
	padding-left:var(--spacing-sm);
}

.c-buffer-box--right {
	padding-right:var(--spacing-sm);
}

.c-buffer-box--vertical {
	padding-left:var(--spacing-sm);
	padding-right:var(--spacing-sm);
}

/* Set extra padding
---------------------------------------------------------------------------- */
.c-buffer-box--extra-bottom {
	padding-bottom:var(--spacing-m);
	
	@media(--sm) {
		padding-bottom:var(--spacing-ml);
	}
	@media(--md) {
		padding-bottom:var(--spacing-l);
	}
	@media(--lg) {
		padding-bottom:var(--spacing-xl);
	}
}

.c-buffer-box--extra-top {
	padding-top:var(--spacing-m);
	@media(--sm) {
		padding-top:var(--spacing-ml);
	}
	@media(--md) {
		padding-top:var(--spacing-l);
	}
	@media(--lg) {
		padding-top:var(--spacing-xl);
	}
}

.c-buffer-box--extra-ends {
	padding-top:var(--spacing-m);
	padding-bottom:var(--spacing-m);
	@media(--sm) {
		padding-top:var(--spacing-ml);
		padding-bottom:var(--spacing-ml);
	}
	@media(--md) {
		padding-top:var(--spacing-l);
		padding-bottom:var(--spacing-l);
	}
	@media(--lg) {
		padding-top:var(--spacing-xl);
		padding-bottom:var(--spacing-xl);
	}
}

.c-buffer-box--extra-left {
	padding-left:var(--spacing-sm);
	@media(--sm) {
		padding-left:var(--spacing-ml);
	}
	@media(--md) {
		padding-left:var(--spacing-l);
	}
	@media(--lg) {
		padding-left:var(--spacing-xl);
	}
}

.c-buffer-box--extra-right {
	padding-right:var(--spacing-sm);
	@media(--sm) {
		padding-right:var(--spacing-ml);
	}
	@media(--md) {
		padding-right:var(--spacing-l);
	}
	@media(--lg) {
		padding-right:var(--spacing-xl);
	}
}

.c-buffer-box--extra-vertical {
	padding-left:var(--spacing-sm);
	padding-right:var(--spacing-sm);
	@media(--sm) {
		padding-left:var(--spacing-ml);
		padding-right:var(--spacing-ml);
	}
	@media(--md) {
		padding-left:var(--spacing-l);
		padding-right:var(--spacing-l);
	}
	@media(--lg) {
		padding-left:var(--spacing-xl);
		padding-right:var(--spacing-xl);
	}
}

/* Set tighter padding
---------------------------------------------------------------------------- */
.c-buffer-box--tight-top[class] {
	padding-top:var(--spacing-s);
	@media(--md) {
		padding-top:var(--spacing-m);
	}
}

.c-buffer-box--tight-ends {
	padding-top:var(--spacing-m);
	padding-bottom:var(--spacing-m);
}

.c-buffer-box--tight-bottom[class] {
	padding-bottom:var(--spacing-m);
}

/* Set super-tight padding
---------------------------------------------------------------------------- */
.c-buffer-box--super-tight-top[class] {
	padding-top:var(--spacing-xs);
	@media(--md) {
		padding-top:var(--spacing-sm);
	}
}

.c-buffer-box--super-tight-bottom[class] {
	padding-bottom:var(--spacing-sm);
}

.c-buffer-box--super-tight-top-ends {
	padding-top:var(--spacing-sm);
	padding-bottom:var(--spacing-sm);
}

/* Set border for only a certain direction.
---------------------------------------------------------------------------- */
.c-buffer-box--border-top {
	border-top-width:1px;
}

.c-buffer-box--border-bottom {
	border-bottom-width:1px;
}

.c-buffer-box--border-left {
	border-left-width:1px;
}

.c-buffer-box--border-right {
	border-right-width:1px;
}

/* Set all borders.
---------------------------------------------------------------------------- */
.c-buffer-box--border-all {
	border-width:1px;
}
