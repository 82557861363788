.bank-id {
	position: relative;
}

.bank-id__control {
	position: relative;
	z-index: 1;
}

.bank-id__icon {
	position: absolute;
	top: 15px;
	right: 16px;
	z-index: 2;
	width: 29px;
	height: 27px;
}
