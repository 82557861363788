/* =============================================================================
 * Combo box
 * 1. Left & right -1px makes the borders overlap.
 * 2. Make button cover whole area and make it clickable
============================================================================= */
.c-combo-box[class] {
	position:relative;
	border:1px solid var(--color-white);
	background-image:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTUuMzc3cHgiIGhlaWdodD0iMjIuNjg0cHgiIHZpZXdCb3g9IjAgMCAxNS4zNzcgMjIuNjg0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNS4zNzcgMjIuNjg0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwb2x5Z29uIGZpbGw9IiM1REIxNDYiIHBvaW50cz0iMS40Myw5LjI1OSAwLjAwOSw3Ljg1MSA3Ljc5MiwwIDE1LjM3Nyw3Ljg2MSAxMy45MzgsOS4yNSA3Ljc3MiwyLjg2IAkiLz4NCgk8cG9seWdvbiBmaWxsPSIjNURCMTQ2IiBwb2ludHM9IjcuNTg1LDIyLjY4NCAwLDE0LjgyMyAxLjQzOSwxMy40MzQgNy42MDQsMTkuODIzIDEzLjk0NywxMy40MjQgMTUuMzY3LDE0LjgzMyAJIi8+DQo8L2c+DQo8L3N2Zz4NCg==);
	background-color:var(--color-white);
	background-repeat:no-repeat;
	background-position:calc(100% - 12px);
	padding:21px 0 3px 0;
	position:relative;
	transition:var(--transition);
}

.c-combo-box:hover,
.c-combo-box:focus {
	border-color:var(--color-turtle-green);
}

.c-combo-box__inner {
	display:block;
	width:90%;
}

.c-combo-box__label {
	@mixin text-style-zeta;
	color:var(--color-battleship-grey);
	position:absolute;
	top:6px;
	left:var(--spacing);
	cursor:pointer;
}

.c-combo-box__content {
	display:none;
	background-color:var(--color-white);
	border:1px solid var(--color-light-grey-green);
	border-top-width:0;
	position:absolute;
	padding:var(--spacing);
	z-index:100;
	left:-1px; /* [1] */
	right:-1px; /* [1] */
	-ms-transform:translateY(3px);
	transform:translateY(3px);
}

.c-combo-box__content--border-top {
	border-top-width:1px;
}

.c-combo-box__trigger {
	width:100%;
	text-align:left;
	padding-left:var(--spacing);
	text-decoration:none;
	border-radius:0;
	border-width:0;
	-ms-flex-pack:start;
	justify-content:flex-start;
	padding-top:21px; /* [2] */
	margin-top:-21px; /* [2] */
	padding-bottom:3px; /* [2] */
	margin-bottom:-3px; /* [2] */
}

@media(--md) {
	.c-combo-box[class] {
		padding:29px 0 6px 0;
		background-position:calc(100% - 18px);
	}

	.c-combo-box__label {
		left:18px;
	}

	.c-combo-box__content {
		-ms-transform:translateY(6px);
		transform:translateY(6px);
		padding:var(--spacing-sm);
	}

	.c-combo-box__trigger {
		padding-left:18px;
		padding-top:29px; /* [2] */
		margin-top:-29px; /* [2] */
		padding-bottom:6px; /* [2] */
		margin-bottom:-6px; /* [2] */
	}
}
