/* =============================================================================
 * Text styles
============================================================================= */
.t-text-style-alpha.t-text-style-alpha {
	@mixin text-style-alpha;
}

.t-text-style-beta.t-text-style-beta {
	@mixin text-style-beta;
}

.t-text-style-gamma.t-text-style-gamma {
	@mixin text-style-gamma;
}

.t-text-style-delta.t-text-style-delta {
	@mixin text-style-delta;
}

.t-text-style-epsilon.t-text-style-epsilon {
	@mixin text-style-epsilon;
}

.t-text-style-zeta.t-text-style-zeta {
	@mixin text-style-zeta;
}

.t-text-style-eta.t-text-style-eta {
	@mixin text-style-eta;
}

.t-text-style-theta.t-text-style-theta {
	@mixin text-style-theta;
}

.t-text-style-iota.t-text-style-iota {
	@mixin text-style-iota;
}

.t-text-style-kappa.t-text-style-kappa {
	@mixin text-style-kappa;
}

.t-text-style-lambda.t-text-style-lambda {
	@mixin text-style-lambda;
}

.t-text-style-mu.t-text-style-mu {
	@mixin text-style-mu;
}

.t-text-style-nu.t-text-style-nu {
	@mixin text-style-nu;
}

.t-text-style-reset.t-text-style-reset {
	@mixin text-style-reset;
}

.t-style-italic {
	font-style:italic;
}

.t-style-bold {
	font-weight:bold;
}
.t-style-error { /* TODO Should maybe be somewhere else? */
	color:#f00;
}
