/* =============================================================================
 * Handle whitespace explicitly
============================================================================= */

/* Clear all floats */
.t-clear {
	clear:both;
}

.t-clear::before {
	content:"\A";
	white-space:pre;
}

/* Avoid all linebreaks */
.t-nowrap {
	white-space:nowrap;
}
