/* =============================================================================
 * List inline
============================================================================= */

/*
 * Default
 */
.o-list-inline {
	font-size:0;
}

.o-list-inline--spacing .o-list-inline__item + .o-list-inline__item {
	padding-left:var(--spacing-s);
}

.o-list-inline__item {
	display:inline-block;
	font-size:var(--type-size-default);
}

/*
 * @md
 */
@media(--md) {
	.o-list-inline\@md {
		font-size:0;
	}

	.o-list-inline\@md .o-list-inline__item {
		display:inline-block;
		font-size:var(--type-size-default);
	}
}

/*
 * @lg
 */
@media(--lg) {
	.o-list-inline\@lg {
		font-size:0;
	}

	.o-list-inline\@lg .o-list-inline__item {
		display:inline-block;
		font-size:var(--type-size-default);
	}
}

/*
 * @xl
 */
@media(--xl) {
	.o-list-inline\@xl {
		font-size:0;
	}

	.o-list-inline\@xl .o-list-inline__item {
		display:inline-block;
		font-size:var(--type-size-default);
	}
}
