/* Text input fields
-------------------------------------------------------------- */
.c-rect-input {
	position:relative;
}

.c-rect-input.top-label .c-rect-input__label {
	transform:translate3d(0, -12px, 0);
	font-size:13px;
}

.c-rect-input.top-label .c-rect-input__input {
	padding-bottom:6px;
	padding-top:24px;
}

.c-rect-input__input {
	@mixin text-style-reset;
	@mixin box-shadow 0.05;
	background:var(--color-white);
	border:1px solid var(--color-white);
	padding:12px;
	transition:var(--transition);
}

.c-rect-input__input:disabled,
.c-select__input:disabled {
	background:rgb(230, 230, 230);
	color:#000;
}

.c-rect-input[type="file"] {
	height: auto;
    padding-bottom: 12px;
    padding-top: 12px;
}

.c-rect-input__input:not(textarea) {
	height:48px;
}

.c-rect-input__input--small.c-rect-input__input:not(textarea) {
	height:36px;
	padding:6px 12px;
}

.c-rect-input__input:hover,
.c-rect-input__input:focus,
.c-rect-input__input:valid {
	border-color:var(--color-turtle-green);
}


.c-rect-input__input:not(.c-rect-input__input--small):focus,
.c-rect-input__input:not(.c-rect-input__input--small):valid,
.c-rect-input__input:disabled,
.c-rect-input__input.has-value,
.c-select__input:disabled,
.c-form__error:not(.c-rect-input__input--small):not(.c-combo-box):not(.c-select__input):not(.c-select__wrapper):not(.c-radio-group) {
	padding-bottom:6px;
	padding-top:24px;
}

.c-rect-input__input:-webkit-autofill,
.c-rect-input__input:-webkit-autofill:hover,
.c-rect-input__input:-webkit-autofill:focus,
.c-rect-input__input:-webkit-autofill:active {
	-webkit-box-shadow:0 0 0 1000px var(--color-white) inset;
}

.c-rect-input__input:not(.c-rect-input__input--small):-webkit-autofill {
	padding-bottom:6px;
	padding-top:24px;
}

.c-rect-input__input[type="date"] {
	width:100%;
}

/* Label */

.c-rect-input__label {
	display:inline-block;
	color:var(--color-main-label);
	position:absolute;
	top:12px;
	left:12px;
	margin-bottom:0px;
	transition:all 0.2s ease-in-out;
	transform:translate3d(0, 0, 0);
}

.c-rect-input__label:hover {
	cursor:text;
}

.c-rect-input__label[data-required-suffix]::after {
	padding-left: .25em;
	content: attr(data-required-suffix);
	speak: none;
}

.c-rect-input__input:-webkit-autofill ~ .c-rect-input__label {
	@mixin text-style-zeta;
	transform:translate3d(0, -6px, 0);
}

.c-rect-input__note {
	font-size: .75rem;
	color: rgb(117, 117, 117);
}

.c-rect-input__input:focus ~ .c-rect-input__label,
.c-rect-input__input:valid ~ .c-rect-input__label,
.c-rect-input__input:disabled + .c-rect-input__label,
.c-rect-input__input.has-value ~ .c-rect-input__label,
.c-select__input:disabled + .c-rect-input__label,
.c-select__input.has-value + .c-rect-input__label,
.c-form__error ~ .c-rect-input__label {
	@mixin text-style-zeta;
	transform:translate3d(0, -6px, 0);
}

@media(--md) {
	.c-rect-input__input {
		padding:18px;
	}

	.c-rect-input__input:not(textarea) {
		height:60px;
	}

	.c-rect-input__input:not(.c-rect-input__input--small):-webkit-autofill {
		padding-bottom:6px;
	}

	.c-rect-input__label {
		top:19px;
		left:18px;
	}

	.c-rect-input__input:-webkit-autofill ~ .c-rect-input__label {
		transform:translate3d(0, -12px, 0);
	}

	.c-rect-input__input:focus ~ .c-rect-input__label,
	.c-rect-input__input:valid ~ .c-rect-input__label,
	.c-rect-input__input:disabled + .c-rect-input__label,
	.c-rect-input__input.has-value ~ .c-rect-input__label,
	.c-select__input:disabled + .c-rect-input__label,
	.c-select__input.has-value + .c-rect-input__label,
	.c-form__error ~ .c-rect-input__label {
		transform:translate3d(0, -12px, 0);
	}
}
