/* =============================================================================
 * Select (stripped)
 * TODO:Replace icon (::after backgound-image)
============================================================================= */
.c-select-stripped {
	position:relative;
	display:inline-block;
}

.c-select-stripped__wrapper {
	width:100%;
	position:relative;
}

.c-select-stripped__wrapper::after {
	content:"";
	background-image:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIuMDYzIDE4LjgzM2wtMTEtMTAuOTE2di0yLjc1bDExIDEwLjgzM0wyMi45MzggNS4yMDh2Mi45NTl6IiBmaWxsPSJyZ2IoMTE3LCAxMTcsIDExNykiLz48L3N2Zz4=);
	background-repeat:no-repeat;
	background-position:center center;
	height:var(--spacing-sm);
	width:var(--spacing-sm);
	position:absolute;
	right:0;
	top:0;
	pointer-events:none;
}

.c-select-stripped__wrapper:hover::after {
	cursor:pointer;
}

.c-select-stripped__input {
	display:block;
	border:none;
	-moz-appearance:none;
	-webkit-appearance:none;
	-webkit-border-radius:0px;
	@mixin text-style-epsilon;
	color:var(--color-battleship-grey);
	padding-right:var(--spacing-sm);
}

.c-select-stripped__input::-ms-expand {
	display:none;
}