.t-position-relative { position: relative; }
.t-position-absolute { position: absolute; }
.t-position-fixed    { position: fixed; }
.t-position-sticky   { position: sticky; }

.t-top-0       { top: 0; }
.t-top-50\%    { top: 50%; }
.t-bottom-0    { bottom: 0; }
.t-bottom-50\% { bottom: 50%; }
.t-left-0      { left: 0; }
.t-left-50\%   { left: 50%; }
.t-right-0     { right: 0; }
.t-right-50\%  { right: 50%; }
.t-inset-0     { top: 0; right: 0; bottom: 0; left: 0; }

/*
 * z-index
 */

:root {
    --z-index-header: 3;
    --z-index-popover: 5;
    --z-index-fixed-element: 10;
    --z-index-overlay: 15;
}

.t-z-index-0 { z-index: 0; }
.t-z-index-1 { z-index: 1; }
.t-z-index-2 { z-index: 2; }
.t-z-index-3 { z-index: 3; }

.z-index-header	        { z-index: var(--z-index-header); } /* e.g., main header */
.z-index-popover	    { z-index: var(--z-index-popover); } /* e.g., ui elements such as tooltips and dropdowns */
.z-index-fixed-element  { z-index: var(--z-index-fixed-element); } /* e.g., 'back to top' button */
.z-index-overlay        { z-index: var(--z-index-overlay); } /* e.g., modals and dialogs */