.c-form-instruction {
	margin-bottom:var(--spacing-s);
	@media(--md) {
		margin-bottom:var(--spacing-m);
	}
}

.c-form-instruction--less-margin {
	margin-bottom:24px;
}

.c-form-instruction__title {
	@mixin text-style-gamma;
	margin-bottom:12px;
}

.c-form-instruction__preamble {
	font-style:italic;
}
