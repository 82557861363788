/* =============================================================================
 * Custom Select
============================================================================= */
.c-select {
	position:relative;
}

.c-select__wrapper {
	@mixin box-shadow 0.05;
	background:var(--color-white);
	border:1px solid var(--color-white);
	overflow:hidden;
	width:100%;
	transition:var(--transition);
	position:relative;
}

.c-select__wrapper::after {
	content:"";
	background-image:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTUuMzc3cHgiIGhlaWdodD0iMjIuNjg0cHgiIHZpZXdCb3g9IjAgMCAxNS4zNzcgMjIuNjg0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNS4zNzcgMjIuNjg0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwb2x5Z29uIGZpbGw9IiM1REIxNDYiIHBvaW50cz0iMS40Myw5LjI1OSAwLjAwOSw3Ljg1MSA3Ljc5MiwwIDE1LjM3Nyw3Ljg2MSAxMy45MzgsOS4yNSA3Ljc3MiwyLjg2IAkiLz4NCgk8cG9seWdvbiBmaWxsPSIjNURCMTQ2IiBwb2ludHM9IjcuNTg1LDIyLjY4NCAwLDE0LjgyMyAxLjQzOSwxMy40MzQgNy42MDQsMTkuODIzIDEzLjk0NywxMy40MjQgMTUuMzY3LDE0LjgzMyAJIi8+DQo8L2c+DQo8L3N2Zz4NCg==);
	background-repeat:no-repeat;
	background-position:calc(100% - 12px) center;
	height:42px;
	position:absolute;
	pointer-events:none;
	width:38px;
	transition:var(--transition);
	top:3px;
	right:0;
}

.c-select__wrapper:hover,
.c-select__wrapper:focus,
.c-select__wrapper:valid {
	border-color:var(--color-turtle-green);
	cursor:pointer;
}

.c-select__wrapper:hover::after {
	cursor:pointer;
}

.c-select__wrapper.c-form__error {
	padding-top:0;
}

.c-select__wrapper.c-form__error[class] select,
.c-select__wrapper.c-form__error[class]:hover select,
.c-select__wrapper.c-form__error[class] select:hover,
.c-select__wrapper.c-form__error[class] select:focus {
	margin-bottom:0;
}

.c-select__label {
	@mixin text-style-zeta;
	color:var(--color-main-label);
	position:absolute;
	top:6px;
	left:12px;
	z-index:10;
}

.c-select__input {
	display:block;
	border:none;
	padding:18px 0 0 12px;
	background-color:var(--color-white);
	height:48px;

	/* Hide default select arrows */
	width:105%;
	-moz-appearance:none;
	-webkit-appearance:none;
	-webkit-border-radius:0px;
}

.c-select__input::-ms-expand {
	display:none;
}


@media(--md) {
	.c-select__input {
		height:60px;
		padding-left:18px;
	}

	.c-select__label {
		left:18px;
	}

	.c-select__wrapper::after {
		height:54px;
		background-position:calc(100% - 18px) 50%;
	}
}
