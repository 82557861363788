/* =============================================================================
 * Logo
============================================================================= */
.c-logo {
	white-space:nowrap;
	@media(--below-md) {
		display:none;
	}
}

.c-logo__image,
.c-logo__text {
	display:inline-block;
	vertical-align:middle;
}

.c-logo__image svg {
	height:35px;
}

.c-logo__link,
.c-logo__link:visited {
	display:block;
	color:var(--color-grass);
	font-size:0;
	text-decoration:none;
	-webkit-hyphens:manual;
	-ms-hyphens:manual;
	hyphens:manual;
}

.c-logo__link--inverted\@sm {
	color:var(--color-white);
}

.c-logo__link--inverted\@sm:hover,
.c-logo__link--inverted\@sm:visited,
.c-logo__link--inverted\@sm:focus {
	color:var(--color-white);
}

.c-logo__link--inverted\@sm .c-logo__image svg {
	fill:var(--color-white);
}

.c-logo__link--blur {
	filter:blur(2px);
}

.c-logo__text {
	@mixin text-style-gamma;
	margin-left:var(--spacing);
}

.c-logo__image svg {
	fill:var(--color-grass);
}

@media(--md) {
	.c-logo__link--inverted\@sm {
		color:var(--color-grass);
	}

	.c-logo__link--inverted\@sm:hover,
	.c-logo__link--inverted\@sm:visited,
	.c-logo__link--inverted\@sm:focus {
		color:var(--color-grass);
	}

	.c-logo__link--inverted\@sm .c-logo__image svg {
		fill:var(--color-grass);
	}
}

@media(--md) {
	.c-logo__image svg {
		height:40px;
	}
}
