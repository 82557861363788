/*
 * Text styles
 */
@define-mixin text-style-mixin-base {
	background:transparent;
	text-transform:none;
	text-shadow:none;
	word-spacing:normal;
	letter-spacing:normal;
}

@define-mixin text-style-reset {
	@mixin text-style-mixin-base;
	color:#1a1a1a;
	font:var(--type-defaults);
	line-height:var(--type-line-height-default);
	@media(--md) {
		font-size:17px;
		line-height:1.41;
	}
}

@define-mixin text-style-secondary {
	@mixin text-style-mixin-base;
	font-family:var(--type-family-secondary);
}

@define-mixin text-style-alpha {
	@mixin text-style-mixin-base;
	font-weight:700;
	font-size:30px;
	line-height:1.09;
	@media(--md) {
		font-size:36px;
	}
	@media(--xl) {
		font-size:44px;
	}
}

@define-mixin text-style-beta {
	@mixin text-style-mixin-base;
	font-weight:700;
	font-size:21px;
	line-height:1.15;
	@media(--md) {
		font-size:22px;
		line-height:1.36;
	}
}

@define-mixin text-style-gamma {
	@mixin text-style-mixin-base;
	font-weight:700;
	font-size:17px;
	line-height:1.33;
	@media(--md) {
		font-size:17px;
		line-height:1.09;
	}
}

@define-mixin text-style-delta {
	@mixin text-style-mixin-base;
	font-weight:700;
	font-size:17px;
	line-height:1.21;
	@media(--md) {
		line-height:1.41;
	}
}

@define-mixin text-style-epsilon {
	@mixin text-style-mixin-base;
	font-weight:400;
	font-size:15px;
	line-height:1.6;
}

@define-mixin text-style-zeta {
	@mixin text-style-mixin-base;
	font-weight:400;
	font-size:12px;
	line-height:1.38;
	@media(--md) {
		font-size:13px;
	}
}

@define-mixin text-style-eta {
	@mixin text-style-mixin-base;
	font-weight:400;
	font-size:12px;
	line-height:24px;
}

@define-mixin text-style-theta {
	@mixin text-style-mixin-base;
	font-weight:700;
	font-size:25px;
	line-height:27px;
	@media(--md) {
		font-size:34px;
		line-height:36px;
	}
}

@define-mixin text-style-iota {
	@mixin text-style-secondary;
	font-weight:700;
	font-size:44px;
	line-height:48px;
}

@define-mixin text-style-kappa {
	@mixin text-style-secondary;
	font-weight:700;
	font-size:28px;
	line-height:30px;
}

@define-mixin text-style-lambda {
	@mixin text-style-secondary;
	font-weight:500;
	font-style:italic;
	font-size:24px;
	line-height:24px;
}

@define-mixin text-style-mu {
	@mixin text-style-secondary;
	font-weight:500;
	font-style:italic;
	font-size:18px;
	line-height:24px;
}

@define-mixin text-style-nu {
	@mixin text-style-secondary;
	font-weight:500;
	font-size:14px;
	line-height:24px;
}
