.t-display-block[class] {
	display:block !important;
}

.t-hidden {
	display:none !important;
}

@media(--s) {
	.t-hidden\@s {
		display:none !important;
	}
}

@media(--sm) {
	.t-hidden\@sm {
		display:none !important;
	}
}

@media(--below-md) {
	.t-hidden\@below-md {
		display:none !important;
	}
}

@media(--md) {
	.t-hidden\@md {
		display:none !important;
	}
}

@media(--below-lg) {
	.t-hidden\@below-lg {
		display:none !important;
	}
}

@media(--lg) {
	.t-hidden\@lg {
		display:none !important;
	}
}

@media(--xl) {
	.t-hidden\@xl {
		display:none !important;
	}
}