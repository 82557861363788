/* top-bar.theme-two */
.c-top-bar {
	box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.1);
	display: block;
	min-height: 60px;
}

.c-top-bar__tool {
	margin-left:36px;
	padding:18px 0;
}

.c-top-bar__tool:first-child {
	margin-left:0;
}

.c-top-bar__link {
	@mixin text-style-epsilon;
	text-decoration:none;
}

.c-top-bar__link:hover {
	text-decoration:underline;
}

.c-top-bar__link--border {
	position:relative;
}

.c-top-bar__tool:last-child .c-top-bar__link--border {
	margin-right:24px;
}

.c-top-bar__link--border::before {
	content:"";
	display:inline-block;
	background:#e6e6e6;
	height:calc(100% + 43px);
	left:-18px;
	position:absolute;
	top:-21px;
	width:1px;
}

.c-top-bar__tool:last-child .c-top-bar__link--border::after {
	content:"";
	display:inline-block;
	background:#e6e6e6;
	height:calc(100% + 43px);
	right:-24px;
	position:absolute;
	top:-21px;
	width:1px;
}

.c-top-bar__link--icon:hover {
	text-decoration:none;
}

.c-top-bar__icon-text {
	padding-left:6px;
}

.c-top-bar__link:hover .c-top-bar__icon-text {
	text-decoration:underline;
}
